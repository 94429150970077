import React, { FC } from "react";
import Layout from "../components/layout";
import { H1, H2, H3, H4, H5, H6 } from "../components/typography";

const IndexPage: FC = () => (
  <Layout
    title="Market news, financial education and more"
    description="Vessel learn teaches and informs you about anything and everything financial. Our articles are written by experts and designed to help you on your financial journey"
    canonical="features"
    siteName="Vessel"
  >
    <H1>Heading 1</H1>
    <H2>Heading 2</H2>
    <H3>Heading 3</H3>
    <H4>Heading 4</H4>
    <H5>Heading 5</H5>
    <H6>Heading 6</H6>
  </Layout>
);

export default IndexPage;
